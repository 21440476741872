import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { Validators, FormControl } from '@angular/forms';
import { ENV, sid, sidtime } from 'src/environments/environment';
import { AgendaAmbulatoriaService } from 'src/app/services/agenda-ambulatoria.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { RegistroUsuarioComponent } from 'src/app/shared/components/modals/registro-usuario/registro-usuario.component';
import { RecuperarClaveUsuarioComponent } from 'src/app/shared/components/modals/recuperar-clave-usuario/recuperar-clave-usuario.component';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import 'moment-timezone';
@Component({
  selector: 'app-buscar-tu-medico',
  templateUrl: './buscar-tu-medico.component.html',
  styleUrls: ['./buscar-tu-medico.component.scss']
})
export class BuscarTuMedicoComponent implements OnInit {

  documento = null;
  documentoFC = new FormControl('');
  clave = new FormControl('', Validators.required);
  agendaBeneficiario;
  isBeneficiario;
  nuevaHoraSaludIntegral;

  @Output() datosBeneficiarioMedico: EventEmitter<any> = new EventEmitter(); 

  constructor(
    public utils: UtilsService,
    public agendaService: AgendaAmbulatoriaService,
    public dialog: MatDialog,
    public activateRoute: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    sessionStorage.clear();

    this.activateRoute.queryParams.subscribe(params => {
      if (params['rut']) {
        this.documento = params['rut'];
        this.documentoFC.setValue(params['rut']);
        this.setFormatRut();
      }

      if (Object.keys(params).length && params['auth']) {
        const authInfo = params['auth'];
        this.setAutoLogin(authInfo);
      }
    });

    this.agendaBeneficiario = this.utils.actionAgendaBeneficiarios().getAgenda().subscribe(res => {
      this.isBeneficiario = res.isTitular;
      this.documento = res.benf.idPaciente;
      this.setProfesionalRol(res.benf.idMedicoCabecera, res.benf);
    });

    this.nuevaHoraSaludIntegral = this.utils.actionNuevaHoraSaludIntegral().getNuevaHora().subscribe(res => {
      this.setFormatRut();
      this.buscarRut();
    });

  }

  eventEnter(event) {
    if (event.keyCode == 13) {
      this.buscarRut();
    }
  }

  restoreFormatRut() {
    if (this.documentoFC.value && this.documentoFC.value.trim() != "") {
      let documento = this.documentoFC.value.trim();
      documento = this.utils.replaceAll(documento, ".", "");
      documento = this.utils.replaceAll(documento, "-", "");
      this.documentoFC.setValue(documento);
    }
  }

  setFormatRut() {
    this.documentoFC.setValue((this.documentoFC.value) ? this.documentoFC.value.trim() : null);
    let rut = this.documentoFC.value;
    if (rut && rut != "") {
      let rutPuntos = this.utils.formatRut(rut);
      this.documentoFC.setValue(rutPuntos);
      this.documento = this.utils.replaceAll(rutPuntos, ".", "");
    }
  }



  async buscarRut() {

    let rut = this.documento;
    let clave = this.clave.value;
    let countError = 0;
    let isAutenticated = false;

    this.documentoFC.markAsTouched();

    if (!rut) {
      this.documentoFC.setErrors({ required: true });
      countError++;
    }

    if (!clave) {
      this.clave.setErrors({ required: true });
      countError++;
    }

    if (!this.utils.validateRun(this.documento)) {
      this.documentoFC.setErrors({ invalidRut: true });
      countError++;
    }

    if (countError > 0) {
      return;
    }

    this.utils.showProgressBar();

    try {
      const detalleAutenticacion: any = await this.agendaService.autenticar(rut, clave);
      if (detalleAutenticacion.statusCod === 'OK') {
        isAutenticated = true;
        sessionStorage.setItem("saludIntegralAccessToken", detalleAutenticacion.accessToken);
        sessionStorage.setItem("saludIntegralIdToken", detalleAutenticacion.idToken);
        sessionStorage.setItem("saludIntegralRefreshToken", detalleAutenticacion.refreshToken);
      } else {
        const commonMsg = 'El rut y la contraseña ingresada no coinciden. Intente nuevamente';
        const message = detalleAutenticacion && detalleAutenticacion.usrMsg ? detalleAutenticacion.usrMsg : commonMsg;
        this.utils.mDialog('Estimado paciente', message, "message");
        this.utils.hideProgressBar();
        this.utils.actionAuthSaludIntegral().set(true);
        return;
      }
    } catch (err) {
      this.utils.mDialog('Estimado paciente', 'No se pudo autenticar, intente de nuevo más tarde.', "message");
      this.utils.hideProgressBar();
      this.utils.actionAuthSaludIntegral().set(true);
      return;

    }

    this.agendaService.validarEnrolamiento(rut.split("-").join("")).then(async (res: any) => {

      const esBeneficiario = (res.estado && res.estado.toUpperCase() === 'OK');

      if (!esBeneficiario) {

        this.documentoFC.setErrors({ notFoundRut: true });
        this.utils.hideProgressBar();
        this.utils.actionAuthSaludIntegral().set(true);

      } else {

        this.documentoFC.markAsUntouched();
        this.documentoFC.setErrors({});
        const paciente = res.beneficiario.find(item => item.idPaciente === rut);
        const rutmed = paciente.idMedicoCabecera;
        res.beneficiario.forEach((val, key) => {
          val.id = val.idPaciente;
        });
        sessionStorage.setItem("beneficiarios", JSON.stringify(res.beneficiario));
        sessionStorage.setItem("planPacienteSaludIntegral", paciente.plan);
        this.setProfesionalRol(rutmed, paciente);
      }

    });

  }
  async setProfesionalRol(rutmed, beneficiario) {

    const message = `En estos momentos no ha posible atender su solicitud. Intente de nuevo más tarde.
      Si el problema persiste, comuníquese con nuestro servicio de Atención al Paciente`;
    try {
      this.agendaService.getDatosProfesional(null, rutmed).subscribe(async (prof: any) => {
        this.setBusquedaCalendario(prof.datosProfesional).then((busqueda: any) => {
          busqueda.fromSaludIntegral = true;
          sessionStorage.setItem("derivacion", JSON.stringify(beneficiario))
          this.datosBeneficiarioMedico.emit(busqueda);
          this.utils.hideProgressBar();
          this.utils.actionAuthSaludIntegral().set(true);
        }).catch(err => {
          console.log(err)
          this.utils.mDialog('Estimado Paciente', message, 'message');
          this.utils.hideProgressBar();
          this.utils.actionAuthSaludIntegral().set(true);
        });
      }, (err) => {
        console.log(err)
        this.utils.mDialog('Estimado Paciente', message, 'message');
        this.utils.hideProgressBar();
        this.utils.actionAuthSaludIntegral().set(true);
      });

    } catch (err) {
      console.log(err)
      this.utils.mDialog('Estimado Paciente', message, 'message');
      this.utils.hideProgressBar();
      this.utils.actionAuthSaludIntegral().set(true);
    }

  }

  async setBusquedaCalendario(prof) {

    return new Promise((resolve, reject) => {


      this.agendaService.getEspecialidadesByProfesional(prof.idProfesionalPRM, null).subscribe(async (srvRequest: any) => {

        try {

          let especialidades = null;
          let listadoEspecialidades = [];
          if (srvRequest && srvRequest.especialidadesPorServicio && srvRequest.especialidadesPorServicio.length > 0) {

            especialidades = srvRequest.especialidadesPorServicio.map(item => {
              item.id = item.idServicio;
              return item;
            });

            const extrasEspecialidades = especialidades.filter(item => {
              const matchExtra = ENV.saludIntegral.mostrarEspecialidadCabeceraExtras.filter(itemExtra => {
                return item.idEspecialidad === itemExtra.idEspecialidad &&
                  item.idServicio === itemExtra.idServicio
              });
              if (matchExtra.length === 1) {
                return true;
              }
            });

            const especialidadAConsiderar = this.isBeneficiario ? ENV.medicinaCabecerMenores : ENV.medicinaCabecerAdultos;
            const espPresencial = especialidadAConsiderar.PRESENCIAL;
            const espTLM = especialidadAConsiderar.TLM
            let procesarEspecialidad: any = {};
            procesarEspecialidad = this.procesarEspecialidadCabeceras(especialidades, espPresencial, espTLM);

            if (!procesarEspecialidad.especialidadPresencial && this.isBeneficiario) {
              console.log("No existe especialidad para Beneficiario - Se establece Especialidad como Adulto");
              const medCabAltura = ENV.medicinaCabecerAdultos;
              procesarEspecialidad = this.procesarEspecialidadCabeceras(especialidades, medCabAltura.PRESENCIAL, medCabAltura.TLM);
            }

            listadoEspecialidades = [
              procesarEspecialidad.especialidadPresencial,
              procesarEspecialidad.especialidadTLM
            ];

            listadoEspecialidades = listadoEspecialidades.concat(extrasEspecialidades);

          } else {

            reject(false);
            return false;

          }

          const especialidad = listadoEspecialidades[0];

          if (!especialidad) {
            reject(false);
            this.utils.hideProgressBar();
            return;
          }

          const profesional = {
            id: prof.idProfesionalPRM,
            idProfesional: prof.idProfesionalPRM,
            nombreProfesional: `${prof.nombres} ${prof.apellidoPaterno} ${prof.apellidoMaterno}`,
            esProfesional: true,
            informacionAdicional: "",
            soloAutoPagador: null,
            urlImagenProfesional: `${prof.urlFoto}`,
            detalle: `${prof.nombres} ${prof.apellidoPaterno} ${prof.apellidoMaterno}`
          };

          const centroAtencion = {
            direccion: {
              calle: null,
              numero: null,
              piso: null,
              comuna: "Región Metropolitana"
            },
            horaApertura: null,
            horaCierre: null,
            idCentro: ENV.idRegion,
            idRegion: ENV.idRegion,
            latitud: null,
            longitud: null,
            nombre: "Todos",
            codigo: "todos",
            detalle: "Todos - Región Metropolitana"
          };

          const centrosDisponibles = [];
          const area = ENV.areaConsultaMedica;
          const idPaciente = await this.getDatosPaciente();
          const documentoPaciente = {
            tipoDocumento: "RUN",
            documento: this.documento,
            documentoFormateado: this.utils.formatRut(this.documento),
            idPaciente: idPaciente
          };

          
          const datosImagenes = {
            aplicaMedioContraste: false,
            archivo: null,
            requierePresupuesto: false,
            idEncuesta: null
          };

          const busqueda = {
            area,
            profesional,
            especialidad,
            centroAtencion,
            documentoPaciente,
            centrosDisponibles,
            fromSaludIntegral: true,
            datosImagenes,
            listadoEspecialidades
          };

          sessionStorage.setItem('profesionalCabeceraCalendario', JSON.stringify(busqueda));
          this.utils.saludIntegralVolver().setVolver('VISTA_DERIVACION');

          resolve(busqueda);


        } catch (err) {
          console.log(err)

          reject(false);
          this.utils.hideProgressBar();

        }

      });

    });


  }

  procesarEspecialidadCabeceras(especialidades, espPresencial, espTLM) {
    let especialidadPresencial;
    let especialidadTLM;

    for (const especialidad of especialidades) {
      const { idServicio, idEspecialidad } = especialidad;
      if (idServicio === espPresencial.id && idEspecialidad === espPresencial.idEspecialidad) {
        especialidadPresencial = especialidad;
      }
      if (idServicio === espTLM.id && idEspecialidad === espTLM.idEspecialidad) {
        especialidadTLM = especialidad;
      }
    }

    return {
      especialidadPresencial,
      especialidadTLM
    }
  }

  getDatosPaciente() {
    return new Promise((resolve, reject) => {

      try {
        const tipoDocumento = 'RUN';
        const documento = this.documento;
        this.agendaService.getPaciente(documento, tipoDocumento, ENV.areaConsultaMedica).subscribe((res: any) => {
          if (res && res.listaPacientes && res.listaPacientes.length > 0) {
            resolve(res.listaPacientes[0].id);
            return;
          }
          resolve(null);
        }, () => {
          resolve(null);
        });

      } catch (err) {
        resolve(null);

      }
    });
  }

  registrarse() {
    this.dialog.open(RegistroUsuarioComponent, {
      data: null,
      width: '720px',
      autoFocus: false,
      disableClose: true
    });
  }

  recuperarClave() {
    this.dialog.open(RecuperarClaveUsuarioComponent, {
      data: null,
      width: '480px',
      autoFocus: false,
      disableClose: true
    });
  }

  setAutoLogin(authInfo) {
    try {



      var key = CryptoJS.enc.Utf8.parse(sid);
      var iv = CryptoJS.enc.Utf8.parse(sid);


      const decodeAuthInfo = CryptoJS.AES.decrypt(decodeURI(authInfo), key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);

      const infoAuth = JSON.parse(decodeAuthInfo);
      const focusTime = moment(Number(infoAuth.timestamp) + sidtime).tz('America/Santiago')
      const expired = moment().tz('America/Santiago').isAfter(focusTime);
      if (!expired) {
        this.documentoFC.setValue(infoAuth.rut);
        this.clave.setValue(infoAuth.clave);
        this.setFormatRut();
        this.buscarRut();
      } else {
        setTimeout(() => {
          this.utils.mDialog('Ha ocurrido un error', "El token de sesión ha expirado. Por favor, ingrese sus credenciales para continuar.", 'message');
          this.utils.actionAuthSaludIntegral().set(true);
        }, 1000);
      }
    } catch (err) {
      console.log(err);
      setTimeout(() => {
        this.utils.mDialog('Ha ocurrido un error', "No ha sido posible autenticar. Por favor, ingrese sus credenciales para continuar.", 'message');
        this.utils.actionAuthSaludIntegral().set(true);
      }, 1000);
    }
  }
}
